html {
  font-size: 62.5%;
  font-family: 'Inter', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', 'Inter';
}

body {
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.react-datepicker {
  font-size: 1em !important;
}
.react-datepicker__header {
  background-color: white !important;
  padding-top: 0.8em !important;
  border-bottom: 0px solid transparent !important;
}
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  display: none !important;
}
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  display: none !important;
}
.react-datepicker__month {
  margin: 0.4em 1em !important;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em !important;
  line-height: 1.9em !important;
  margin: 0.166em !important;
  font-weight: 400 !important;
}
.react-datepicker__current-month {
  font-size: 1em !important;
  margin-bottom: 1.5em !important;
}
.react-datepicker__navigation {
  top: 1em !important;
  line-height: 1.7em !important;
  border: 0.45em solid transparent !important;
}
.react-datepicker__day-names {
  font-weight: bolder !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #101928 !important;
  border-radius: 50% !important;
  font-weight: 600 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #101928 !important;
  border-radius: 50% !important;
  font-weight: 600 !important;
}

/* overrides */

.react-router-link {
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  margin: 4px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
